/* -----------------------------------------------------------------------------------------

    THSI FILE CONTAINS SOME STATIC DATA TO FILL SOME DIFFERENT COMPONENTS

----------------------------------------------------------------------------------------- */

import React from 'react';

/* FAQS LIST - ADD OR DELETE FROM THEM AS YOU NEED */
export const FAQS = [
    {
        question: 'How do I create an account?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    Click on the link "DISCOVER".
                </p>
            </div>
        ),
    },
    {
        question: 'Where can I get a Bublik?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    You can buy a bagel on ISTOKS or PancakeSwap
                </p>
            </div>
        ),
    },
    {
        question: 'How do I subscribe?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>Top up your wallet with a bagel and watch your favorite content.</p>
            </div>
        ),
    },
    {
        question: 'Who is the owner of the Public Video service?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>Meme coin Bulikcoin.com</p>
            </div>
        ),
    },
];
